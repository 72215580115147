@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

/** @format */
* {
  /* --primaryColor: #002E6E; */
  --secondaryColor: #dc3545;
  --fourthColor: #6c757d;
  --tertiaryColor: #4d2157;
  --white: #ffffff;
  font-family: "Figtree";
}

.konfhub-logo{
  width: 125px;
  height: 35px;
  margin-bottom: 30px;
}

/* Purple Outline */

.purpleOutline {
  outline: 2px solid var(--primaryColor) !important;
}

/* Text and BG color */

.primaryBackgroundColor {
  background-color: #572148 !important;
}

.secondaryBackgroundColor {
  background-color: #dc3545 !important;
}

html {
  position: relative;
  min-height: 100%;
  background-color: white;
}

.intl-tel-input .arrow {
  pointer-events: none;
}


@media screen and (max-width: 712px) {
  .text_heading {
    /* color: #fb5850 !important;
    font-size: 64px !important;
    padding-left: 10px !important; */
  }
  .heading {
    margin-top: -83px !important;
  }
}
